import React from 'react';
import { Link } from 'gatsby';
import errorImg from '../assets/images/error.png';

const ErrorPage = () => {
  return (
    <div className="error-area">
      <div className="container" style={{ height: '100%' }}>
        <div className="error-content">
          <img src={errorImg} alt="Error" />

          <h3>Page Not Found!</h3>
          <p>
            We are sorry for this but the page you are looking for does not
            exist
          </p>

          <div className="btn-box">
            <Link to="/" className="default-btn">
              <i className="flaticon-right"></i>
              Go To Homepage <span></span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
